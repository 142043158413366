import { CommonModule, Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { RechargeService } from 'src/app/recharge-plans-page/services/recharge.service';
import { Client, CountryCodeRes, paymentInfo } from './model';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';

declare var Stripe: any;
const STRIPE_KEY = environment.STRIPE_KEY;

@Component({
  selector: 'app-stripe-payment-gateway',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MatMenuModule,
    MatSnackBarModule,
  ],
  templateUrl: './stripe-payment-gateway.component.html',
  styleUrls: ['./stripe-payment-gateway.component.scss'],
})
export class StripePaymentGatewayComponent implements OnInit, OnDestroy {
  card: any = null;
  stripe: any = null;
  country: string = 'India';
  countryCode: string = 'IN';
  model: any = {};
  cname: string = '';
  emailerr: boolean = false;
  phoneerr: boolean = false;
  cancel: boolean = true;
  mounted: boolean = false;
  clientRes: Client = null;
  paymentInfo: paymentInfo;
  planID: string = null;
  town: string = '';
  streetAddress: string = '';
  postCode: string = '';
  state: string = '';
  showAddressSection: boolean = true;
  showPaymentSection: boolean = false;
  addressErr: boolean = false;
  disableClose: boolean = false;
  clientToken: string = '';
  constructor(
    private router: Router,
    private rechargeService: RechargeService,
    private _snackBar: MatSnackBar,
    private location: Location
  ) {}
  ngOnInit(): void {
    this.getCountryCodeApi();
    // get base url
    const parsedUrl = new URL(window.location.href);
    let baseUrl: string = parsedUrl.origin;
    // set full path url
    let currentUrl = baseUrl + '' + this.router.url;

    // convert string to url object
    let url = new URL(currentUrl);

    // get params by key from  url
    let key = url.searchParams.get('key');
    var decodedKey = decodeURIComponent(key);
    let decrptedString = this.rechargeService.decryptData(decodedKey);
    let decrptedJsonObject: any = JSON.parse(decrptedString);
    if (decrptedJsonObject) {
      this.paymentInfo = decrptedJsonObject?.paymentInfo;
      this.planID = decrptedJsonObject?.planID;
      this.clientRes = decrptedJsonObject?.clientRes;
      this.clientToken = decrptedJsonObject?.clientToken;
    }
  }

  ngOnDestroy(): void {}

  initGatewayFields() {
    const elements = this.stripe.elements();
    this.card = elements.create('card');
    this.card.mount('#card-element');
  }

  // Show the customer the error from Stripe if their card fails to charge
  showError(errorMsgText: any) {
    this.loading(false);
    var errorMsg = document.querySelector('#card-error');
    if (errorMsg) {
      errorMsg.textContent = errorMsgText;
      setTimeout(function () {
        errorMsg.textContent = '';
      }, 10000);
    }
  }

  // Show a spinner on payment submission
  loading(isLoading: any) {
    if (isLoading) {
      // Disable the button and show a spinner
      document.querySelector('button').disabled = true;
      document.querySelector('#spinner').classList.remove('hidden');
      document.querySelector('#button-text').classList.add('hidden');
    } else {
      document.querySelector('button').disabled = false;
      document.querySelector('#spinner').classList.add('hidden');
      document.querySelector('#button-text').classList.remove('hidden');
    }
  }

  close() {
    this.location.back();
  }

  onEmailInput() {
    this.emailerr = false;
  }

  onPhoneIput(event: any) {
    if (!event.target.value.startsWith('+')) {
      this.phoneerr = true;
    } else {
      this.phoneerr = false;
    }
  }

  async onPayNow(f: any) {
    this.loading(true);
    if (this.paymentInfo.total < 0.5) {
      this.loading(false);
      return;
    }
    if (this.phoneerr) {
      return;
    }

    var pattern = new RegExp(
      '^[a-zA-Z0-9_+&-]+(?:\\.[a-zA-Z0-9_+&-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,7}$'
    );
    if (!pattern.test(f.value.email)) {
      this.loading(false);
      this.emailerr = true;
      return;
    }
    var pattern1 = new RegExp(
      '(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))s*[)]?[-s.]?[(]?[0-9]{1,3}[)]?([-s.]?[0-9]{3})([-s.]?[0-9]{3,4})'
    );
    if (!pattern1.test(f.value.phone)) {
      this.loading(false);
      this.phoneerr = true;
      return;
    }
    let stripePaymentMethodDetails = {
      type: 'card',
      card: this.card,
      billing_details: {
        name: f.value.cardUserName,
        email: f.value.email,
        phone: f.value.phone,
        address: {
          city: this.town,
          country: this.countryCode,
          line1: this.streetAddress,
          // line2: null,
          postal_code: this.postCode,
          state: this.state,
        },
      },
    };
    this.disableClose = true;
    try {
      let paymentMethodResult = await this.stripe.createPaymentMethod(
        stripePaymentMethodDetails
      );
      // console.log("createPaymentMethod", result.paymentMethod?.id);
      if (paymentMethodResult.error) {
        // Show error in payment form
        console.error(paymentMethodResult.error);
        this.showError(paymentMethodResult.error.message);
        this.loading(false);
        this.disableClose = false;
      } else {
        let req = {
          paymentMethodId: paymentMethodResult.paymentMethod?.id,
          planID: this.planID,
        };
        let paymentIntentRes: {
          clientSecret: string;
          status: string;
          data: any;
        } = (await this.rechargeService
          .createPaymentIntent(this.clientRes?.id, req, this.clientToken)
          .toPromise()) as any;
        // console.log(paymentIntentRes);

        // console.log('PAYMENT_INTENT', paymentIntentRes);

        let confirmCardRes: { paymentIntent: any; error: any } =
          await this.stripe.confirmCardPayment(paymentIntentRes.clientSecret, {
            payment_method: stripePaymentMethodDetails,
          });
        // console.log("confirmCardPayment", result);
        this.loading(false);
        this.disableClose = false;
        if (confirmCardRes.error) {
          this.disableClose = false;
          console.error(confirmCardRes.error);
          this.showError(confirmCardRes.error.message);
        } else {
          if (confirmCardRes?.paymentIntent?.status == 'succeeded') {
            console.log('Payment succeeded');
            this.showToast('Payment succeeded', 5000);

            let gatewayElementParent = document.getElementById('card-element');
            while (gatewayElementParent.firstChild) {
              gatewayElementParent.removeChild(gatewayElementParent.firstChild);
            }
            this.cancel = false;
            this.disableClose = false;
            this.close();
          } else {
            this.disableClose = false;
            console.log('Something went wrong');
            this.showToast('Something went wrong', 5000);
          }
        }
      }
    } catch (error: any) {
      this.disableClose = false;
      this.showError(error.error.message);
      this.loading(false);
    }
  }

  onCLickAddressNext() {
    this.addressErr = false;
    if (
      !this.town ||
      !this.country ||
      !this.streetAddress ||
      !this.state ||
      !this.postCode
    ) {
      this.addressErr = true;
      return;
    }
    this.showAddressSection = false;
    this.showPaymentSection = true;

    setTimeout(() => {
      this.stripe = Stripe(STRIPE_KEY);

      if (this.stripe) {
        this.initGatewayFields();
      }
    }, 1000);
  }

  becktoAddress() {
    this.showPaymentSection = false;
    this.showAddressSection = true;
  }
  countryCodes: CountryCodeRes[];

  getCountryCodeApi() {
    this.rechargeService
      .getCountrycode()
      .then((res: CountryCodeRes[]) => {
        this.countryCodes = res;
        console.log(res);
      })
      .catch((err: HttpErrorResponse) => {
        if (err.status != 401) {
          setTimeout(() => {
            this.getCountryCodeApi();
          }, 5000);
        }
        console.error(err);
      });
  }

  selectCountryCodeValue(x: CountryCodeRes) {
    this.country = x.country;
    this.countryCode = x.cntry;
  }

  showToast(
    msg: string,
    duration: number = 3000,
    horizontalPosition: any = 'end',
    verticalPosition: any = 'bottom'
  ) {
    this._snackBar.open(msg, '', {
      duration: duration,
    });
  }
}
