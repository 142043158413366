import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as CryptoJs from 'crypto-js';
import { environment } from 'src/environments/environment';
const cryptoSecretKey = environment.SECRET_KEY;
const kConnectAPI = environment.connectNowAPI;
const kayoolUserServiceUrl = environment.kayoolUserServicePath;

@Injectable({
  providedIn: 'root',
})
export class RechargeService {
  constructor(private http: HttpClient) {}

  decryptData(encryptedData: string): string {
    const decrypted = CryptoJs.AES.decrypt(
      encryptedData,
      cryptoSecretKey
    ).toString(CryptoJs.enc.Utf8);
    return decrypted;
  }

  createPaymentIntent(clientID: string, req: any, clientToken: string) {
    console.log(clientToken);

    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${clientToken}`,
      Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
    return this.http.post(
      `${kConnectAPI}/v1/payments/client/${clientID}/plan/recharge/stripe/payment_intent`,
      req,
      { headers: httpHeaders }
    );
  }

  // get country codes
  getCountrycode(): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = `${kayoolUserServiceUrl}/public/v3/users/search/country-code`;
      this.http.get(url).subscribe({
        next: (r: any) => {
          resolve(r);
        },
        error: (err: HttpErrorResponse) => {
          reject(err);
        },
        complete: () => {
          console.log('complete');
        },
      });
    });
  }
}
